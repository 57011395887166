<template>
  <div>
    <div>
      <Header @selCat="selCat" @selPrice="selPrice" />
    </div>
    <div>
      <Middle ref="goodsListContent" @initCart="initCart" msg="新品专区" />
    </div>
  </div>
</template>

<script>
import Header from "../allGoods/header";
import Middle from "../allGoods/middle";

export default {
  components: {
    Header,
    Middle,
  },
  data: function () {
    return {};
  },
  methods: {
    //设置价格
    selPrice(val) {
      this.$refs.goodsListContent.selPrice(val);
    },
    //设置
    selCat(value) {
      this.$refs.goodsListContent.setType(value.cls_no);
    },
    //初始化
    initCart() {
      this.$emit("initCart");
    },
  },
};
</script>
